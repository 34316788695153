import React, { useState } from "react";
import brochure from "../imgs/brochure_comp.jpg";
import brochureIta from "../docs/StudioMateraBrochure_2022_Ita.pdf";
import brochureEng from "../docs/StudioMateraBrochure_2022_Eng.pdf";
import circolari from "../imgs/circolari.jpg";
import { ListGroup, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import pdficon from "../icons/pdf-icon.png";
import useAxios from "axios-hooks";

const Divisore1 = (props) => {
    const [showCircolari, setShowCircolari] = useState(false);

    function openBrochure() {
        if (props.lang === 'it') window.open(brochureIta);
        else window.open(brochureEng);
    }

    return (
        <section className={"divider"}>
            <Circolari show={showCircolari} onHide={() => setShowCircolari(false)} />
            <div className={"container"} id={"div1"}>
                <div data-aos={"fade-left"}>
                    <img src={brochure} alt={""} onClick={() => openBrochure()} />
                    <p>
                        <FormattedMessage
                            id={"div1.brochure"}
                            defaultMessage={"Scarica <span>la brochure</span>"}
                            values={{ span: (w) => <span className={"text-color"} onClick={() => openBrochure()}>{w}</span> }}
                        />
                    </p>
                </div>
                <div data-aos={"fade-right"}>
                    <p>
                        <FormattedMessage
                            id={"div1.circolari"}
                            defaultMessage={"Visualizza <span>le ultime circolari</span>"}
                            values={{ span: (w) => <span className={"text-color"} onClick={() => setShowCircolari(true)}>{w}</span> }}
                        />
                    </p>
                    <img src={circolari} alt={""} onClick={() => setShowCircolari(true)} />
                </div>
            </div>
        </section>
    )
};

const Circolari = (props) => {
    const [{ data, loading, error },] = useAxios('../docs/docs.json');
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error!</p>;
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage
                        id={"div1.circolari.title"}
                        defaultMessage={"Circolari"}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <FormattedMessage
                        id={"div1.circolari.content"}
                        defaultMessage={"Circolari scaricabili:"}
                    />
                </p>
                <ListGroup>
                    {
                        data.docs.sort((a, b) => {
                            a = new Date(a.d);
                            b = new Date(b.d);
                            return a > b ? -1 : a < b ? 1 : 0;
                        })
                            .map(el => (
                                <ListGroup.Item
                                    action
                                    onClick={() => window.open("https://studiomatera.eu/docs/" + el.src)}
                                >
                                    <img src={pdficon} alt={""} className={"pdficon"} />{el.title}
                                </ListGroup.Item>
                            ))
                    }
                </ListGroup>
            </Modal.Body>

        </Modal>
    )
};

export default Divisore1;