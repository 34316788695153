import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import * as serviceWorker from './serviceWorker';
import Loader from './sections/Loader';

window.onload = () => {
  let loader = document.getElementById("loader");
  let op = 1;  // initial opacity
  let timer = setInterval(function () {
    if (op <= 0.1) {
      clearInterval(timer);
      loader.style.display = 'none';
    }
    loader.style.opacity = op;
    op -= op * 0.1;
  }, 50);
};

ReactDOM.render(
  <React.StrictMode>
    <Loader />
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
