import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import App from './App';
import Calendly from './sections/Calendly';
import ServiziOnline from './sections/ServiziOnline';
import ErrorPage from './sections/ErrorPage';

export default createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'prenota',
                element: <Calendly />
            },
            {
                path: 'servizi',
                element: <ServiziOnline />
            }
        ]
    },
])