import React from "react";
import { useState } from "react";
import closeIcon from "../icons/close_icon.svg"
import logoCommercialistiGE from "../logos/commercialisti_genova.png";
import logoAifo from "../logos/aifo.png";
import logoCaw from "../logos/caw.png";
import logoIcaew from "../logos/icaew.png";
import logoTaxAdvisers from "../logos/tax_advisers.png";
import logoCommercialisti from "../logos/commercialisti.png";
import logoUni from "../logos/universita_genova.png";
import logoAcca from "../logos/acca.png";
import logoUKBA from "../logos/uk_ba.png";
import badgeUKBA from "../imgs/UKBA_studiomatera.jpg"

const Loghi = () => {

    const [showUKBA, setShowUKBA] = useState(false);

    return (
        <section className={"divider"}>
            <div className={"container"} id={"logos-container"}>
                <span data-aos={"zoom-in"}><img src={logoCommercialistiGE} alt={"commercialisti genova"} /></span>
                <span data-aos={"zoom-in"}><img src={logoAifo} alt={"aifo"} /></span>
                <span data-aos={"zoom-in"}><img src={logoAcca} alt={"acca"} /></span>
                <span data-aos={"zoom-in"}><img src={logoCaw} alt={"caw"} /></span>
                <span data-aos={"zoom-in"}><img src={logoIcaew} alt={"icaew"} /></span>
                <span data-aos={"zoom-in"}><img src={logoTaxAdvisers} alt={"tax advisers"} /></span>
                <span data-aos={"zoom-in"}><img src={logoCommercialisti} alt={"commercialisti"} /></span>
                <span data-aos={"zoom-in"}><img src={logoUni} alt={"università di genova"} /></span>
                <span data-aos={"zoom-in"} class="scale-on-hover" onClick={() => setShowUKBA(true)}><img src={logoUKBA} alt={"uk business awards"} /></span>
            </div>
            {showUKBA &&
                <div class="overlay-image" data-aos={"fade-in"} onClick={() => setShowUKBA(false)}>
                    <div>
                        <img src={badgeUKBA} alt={"uk business awards"} />
                        <img class="close-icon" src={closeIcon} alt={"close"} onClick={() => setShowUKBA(false)}/>
                    </div>
                </div>
            }
        </section>
    )
};

export default Loghi