import React from "react";
import tel from "../icons/telefono_dark.png";
import telCol from "../icons/telefono_col.png";
import mail from "../icons/mail_dark.png";
import mailCol from "../icons/mail_col.png";
import facebook from "../icons/facebook.png";
import facebookCol from "../icons/facebook_col.png";
import linkedin from "../icons/linkedin.png";
import linkedinCol from "../icons/linkedin_col.png";
import whatsapp from "../icons/whatsapp.png";
import whatsappCol from "../icons/whatsapp_col.png"
import { FormattedMessage } from "react-intl";
import { CalendarCheck } from 'phosphor-react'
import { useNavigate } from "react-router-dom";

const Contattaci = () => {

    const navigate = useNavigate();

    return (
        <section className={"container"}>
            <div className={"section-header"}>
                <p>
                    <FormattedMessage
                        id={"contatti.title"}
                        defaultMessage={"CONTATTACI"}
                    />
                </p>
                <div />
            </div>
            <div className={"section-content"}>
                <div id={"contattaci-grid"}>
                    <div id={"contattaci-phoneandmail"} data-aos={"fade-right"}>
                        <span>
                            <img
                                src={tel}
                                alt={"tel"}
                                id={"contacts-tel"}
                                style={{ cursor: "pointer" }}
                                onMouseOver={e => e.target.src = telCol}
                                onMouseLeave={e => e.target.src = tel}
                                onClick={() => window.location = "tel:+393405869174"}
                            />
                        </span>
                        <p
                            style={{ cursor: "pointer" }}
                            onMouseOver={() => document.getElementById("contacts-tel").src = telCol}
                            onMouseLeave={() => document.getElementById("contacts-tel").src = tel}
                            onClick={() => window.location = "tel:+393405869174"}
                        >
                            +39 340 5869174
                        </p>
                        <span>
                            <img
                                src={mail}
                                alt={"mail"}
                                id={"contacts-mail"}
                                style={{ cursor: "pointer" }}
                                onMouseOver={e => e.target.src = mailCol}
                                onMouseLeave={e => e.target.src = mail}
                                onClick={() => window.location = "mailto:segreteria@studiomatera.eu"}
                            />
                        </span>
                        <p
                            style={{ cursor: "pointer" }}
                            onMouseOver={() => document.getElementById("contacts-mail").src = mailCol}
                            onMouseLeave={() => document.getElementById("contacts-mail").src = mail}
                            onClick={() => window.location = "mailto:segreteria@studiomatera.eu"}
                        >
                            segreteria@studiomatera.eu
                        </p>
                    </div>
                    <div id={"contattaci-social"} data-aos={"fade-left"}>
                        <p>
                            <FormattedMessage
                                id={"contatti.connettiti"}
                                defaultMessage={"Connettiti con noi:"}
                            />
                        </p>
                        <div>
                            <div>
                                <img
                                    src={facebook}
                                    alt={"facebook"}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => window.open("https://www.facebook.com/studiomatera.eu")}
                                    onMouseEnter={e => e.target.src = facebookCol}
                                    onMouseLeave={e => e.target.src = facebook}
                                />
                            </div>
                            <div>
                                <img
                                    src={linkedin}
                                    alt={"linkedin"}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => window.open("https://www.linkedin.com/in/materandrea")}
                                    onMouseEnter={e => e.target.src = linkedinCol}
                                    onMouseLeave={e => e.target.src = linkedin}
                                />
                            </div>
                            <div>
                                <img
                                    src={whatsapp}
                                    alt={"whatsapp"}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => window.open("https://wa.me/+393405869174")}
                                    onMouseEnter={e => e.target.src = whatsappCol}
                                    onMouseLeave={e => e.target.src = whatsapp}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    className={"custom-btn "}
                    id={"prenota-btn"}
                    onClick={() => navigate('/prenota')}
                    data-aos={"zoom-in"}
                >
                    <span><FormattedMessage
                        id={"contatti.prenota"}
                        defaultMessage={"Prenota una consulenza"}
                    /></span>
                    <CalendarCheck size={24} weight={'bold'} className={"btn-icon"} />
                </button>
                <button
                    className={"custom-btn"}
                    id={"preventivo-btn"}
                    onClick={() => window.location = "mailto:segreteria@studiomatera.eu"}
                    data-aos={"zoom-in"}
                >
                    <span><FormattedMessage
                        id={"contatti.preventivo"}
                        defaultMessage={"Richiedi un preventivo"}
                    /></span>
                </button>
            </div>
        </section>
    )
};

export default Contattaci;