import React, { useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import emailjs, { init }from '@emailjs/browser';
init("thYKmYj8Zn_s1rS95");

const ContactForm = (props) => {
    const [state, setState] = useState({
        name: null,
        email: null,
        location: null,
        message: null,
        gdpr: null,
        valid: false,
        result: null,
    });

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            var mailParams = {
                from_name: state.name,
                reply_to: state.email,
                location: state.location,
                message: state.message,
            };

            emailjs.send('service_zhr9idg', 'template_gn8jx7j', mailParams)
                .then(function(response) {
                    console.log('Email succesfully sent!', response.status, response.text);
                    setState({...state, result: 1});
                }, function(error) {
                    console.log('Email failed',error);
                    setState({...state, result: -1});
                });
        }
        setState({...state, valid: true});
    };

    return (
        <div className={"container"}>
            <hr style={{width: "50%", margin: "2em auto"}}/>
            <h3 style={{textAlign: 'center', marginBottom: '2rem'}}>
                <FormattedMessage
                        id={"contactForm.title"}
                        defaultMessage={"Oppure compila il modulo seguente"}
                    />
            </h3>
            <Form noValidate validated={state.valid} onSubmit={handleSubmit}>
                <Row className={"mb-3"}>
                    <Form.Group as={Col} md={"6"}>
                        <Form.Label>
                            {props.lang==="it" && <>Nome e Cognome</>}
                            {props.lang==="en" && <>Name and Surname</>}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nome e cognome"
                            value={state.name}
                            onChange={e => setState({...state, name: e.target.value})}
                            required
                        />
                        <Form.Control.Feedback type={"invalid"}>
                            Inserisci un nome valido
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md={"6"}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="E-mail"
                            value={state.email}
                            onChange={e => setState({...state, email: e.target.value})}
                            required
                        />
                        <Form.Control.Feedback type={"invalid"}>
                            Inserisci un'email valida'
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Row className={"mb-3"}>
                    <Form.Group as={Col} md={"6"}>
                        <Form.Label>
                            {props.lang==="it" && <>Località</>}
                            {props.lang==="en" && <>From</>}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Città, Provincia, ..."
                            value={state.location}
                            onChange={e => setState({...state, location: e.target.value})}
                            required
                        />
                        <Form.Control.Feedback type={"invalid"}>
                            {props.lang==="it" && <>Inserisci una località valida (Città, Provincia, ...)</>}
                            {props.lang==="en" && <>Insert a valid location (City, Region, ...)</>}
                            
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Form.Group>
                    <Form.Label>
                            {props.lang==="it" && <>Messaggio</>}
                            {props.lang==="en" && <>Message</>}
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="Inserisci il tuo messaggio qui..."
                        value={state.message}
                        onChange={e => setState({...state, message: e.target.value})}
                        required
                    />
                    <Form.Control.Feedback type={"invalid"}>
                        Inserisci il messaggio che vuoi inviare
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        required
                        label="Proseguendo accetto la privacy policy"
                        feedback="Per proseguire, accetta i termini"
                        feedbackType="invalid"
                        value={state.gdpr}
                        onChange={e => setState({...state, gdpr: e.target.value})}
                    />
                </Form.Group>

                <Button variant="primary" type="submit" id={"submit-contact-form"} className={"custom-btn custom-btn-inline"} style={{fontSize: '16pt'}}>
                    Invia
                </Button>

                {state.result===1 && <p style={{color: "green"}}>Messaggio inviato correttamente</p>}
                {state.result===-1 && <p style={{color: "red"}}>Errore nell'invio del messaggio</p>}
            </Form>
        </div>
    )
};

export default ContactForm;