import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import { FormattedMessage } from "react-intl";
import { CalendarCheck } from "phosphor-react";
import { useNavigate } from "react-router-dom";

const ChiSiamo = () => {

    const [playVideo, setPlayVideo] = useState(0);
    const NUM_VIDEOS = 3;
    const navigate = useNavigate()

    const videos = useRef([])

    function endedVideo(id) {
        videos.current[id].seekTo(0)
        setPlayVideo((id + 1) % NUM_VIDEOS)
        videos.current[id].showPreview()
    }

    return (
        <>
            <div className={"waves-back"} />
            <section className={"container"}>
                <div className={"section-header"}>
                    <FormattedMessage
                        id={"chisiamo.title"}
                        defaultMessage={"CHI SIAMO"}
                    />
                    <div />
                </div>
                <div className={"section-content"}>
                    <p id={"chi-siamo"}>
                        <FormattedMessage
                            id={"chisiamo.paragraph"}
                            defaultMessage={"Siamo uno studio professionale dinamico che crede nelle idee e nei progetti dei propri clienti e che accetta le loro sfide. Per riuscire a realizzare i loro obiettivi forniamo la migliore consulenza in ambito societario, aziendale, fiscale e amministrativo. Come? Anticipando le loro necessità!"}
                        />

                    </p>
                </div>
                <button
                    className={"custom-btn "}
                    id={"prenota-btn"}
                    onClick={() => navigate('/prenota')}
                    data-aos={"zoom-in"}
                >
                    <span><FormattedMessage
                        id={"contatti.prenota"}
                        defaultMessage={"Prenota una consulenza"}
                    /></span>
                    <CalendarCheck size={24} weight={'bold'} className={"btn-icon"} />
                </button>

                <div className={"quotation"} data-aos={"zoom-out"} style={{ maxWidth: '800px' }}>
                    <h2>
                        <FormattedMessage
                            id={'chisiamo.quote1'}
                            defaultMessage={'Menzionato da Forbes Italia tra i 25 migliori studi professionali del Paese in tema di servizi digitali'}
                        />
                    </h2>
                    <p>- Forbes Italia</p>
                </div>
                <div className={"quotation"} data-aos={"zoom-out"} style={{ maxWidth: '800px', marginTop: 0 }}>
                    <h2>
                        <FormattedMessage
                            id={'chisiamo.quote2'}
                            defaultMessage={'Nominato vincitore agli UK Business Awards nella categoria Tax Advisory Firm of the Year'}
                        />
                    </h2>
                    <p>- UK Business Awards</p>
                </div>
            </section>
            <div id={"chisiamo-videos"}>
                <div className={"player-wrapper player-wrapper-vertical"}>
                    <ReactPlayer url={"https://youtu.be/g5OXDLbN_zo?wmode=transparent"} controls width={'100%'} height={'100%'} muted playing={playVideo === 0} onEnded={() => endedVideo(0)} ref={el => videos.current[0] = el} />
                </div>
                <div className={"player-wrapper"}>
                    <ReactPlayer url={"https://youtu.be/H_J2Bg2xjRk?wmode=transparent"} controls width={'100%'} height={'100%'} muted playing={playVideo === 1} onEnded={() => endedVideo(1)} ref={el => videos.current[1] = el} />
                </div>
                <div className={"player-wrapper"}>
                    <ReactPlayer url={"https://youtu.be/SVuYM5lbMdE?wmode=transparent"} controls width={'100%'} height={'100%'} muted playing={playVideo === 2} onEnded={() => endedVideo(2)} ref={el => videos.current[2] = el} />
                </div>
            </div>
        </>
    )
};

export default ChiSiamo;