import React from "react";
import loader from "../icons/loader.gif";

const Loader = () => {
    return (
        <div id={"loader"}>
            <img src={loader} alt={"Loading..."}/>
        </div>
    )
};

export default Loader;