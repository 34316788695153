import React, { useState } from "react";
import icon1 from "../icons/consulenza_familiare.png";
import icon2 from "../icons/consulenza_societaria.png";
import icon3 from "../icons/operazioni_straordinarie.png";
import icon4 from "../icons/internazionalizzazione.png";
import icon5 from "../icons/valutazione_azienda.png";
import icon6 from "../icons/consulenza_fiscale.png";
import icon7 from "../icons/controllo_gestione.png"
import icon8 from "../icons/xero_certified.png";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

let lang;

const Servizi = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const [modalTopic, setModalTopic] = useState("");
    const navigate = useNavigate();

    lang = props.lang;
    function popUp(topic) {
        setModalTopic(topic);
        setModalShow(true);
    }

    return (
        <section className={"container"}>
            <div className={"section-header"}>
                <p>
                    <FormattedMessage
                        id={"servizi.title"}
                        defaultMessage={"I NOSTRI SERVIZI"}
                    />
                </p>
                <div />
            </div>
            <p className={"section-info"}><i>
                <FormattedMessage
                    id={"servizi.info"}
                    defaultMessage={"(clicca su un servizio per maggiori informazioni)"}
                />
            </i></p>

            <PopUp show={modalShow} onHide={() => setModalShow(false)} topic={modalTopic} />

            <div className={"section-content"}>
                <div id={"servizi-grid"}>
                    <div onClick={() => popUp("consulenza familiare")} data-aos={"zoom-in"}>
                        <img src={icon1} alt={"consulenza familiare"} />
                        <p>
                            <FormattedMessage
                                id={"servizi.familiare"}
                                defaultMessage={"CONSULENZA FAMILIARE"}
                            />
                        </p>
                    </div>
                    <div onClick={() => popUp("consulenza societaria")} data-aos={"zoom-in"}>
                        <img src={icon2} alt={"consulenza societaria"} />
                        <p>
                            <FormattedMessage
                                id={"servizi.societaria"}
                                defaultMessage={"CONSULENZA SOCIETARIA"}
                            />
                        </p>
                    </div>
                    <div onClick={() => popUp("operazioni straordinarie")} data-aos={"zoom-in"}>
                        <img src={icon3} alt={"operazioni straordinarie"} />
                        <p>
                            <FormattedMessage
                                id={"servizi.straordinarie"}
                                defaultMessage={"OPERAZIONI STRAORDINARIE"}
                            />
                        </p>
                    </div>
                    <div onClick={() => popUp("internazionalizzazione")} data-aos={"zoom-in"}>
                        <img src={icon4} alt={"internazionalizzazione"} />
                        <p>
                            <FormattedMessage
                                id={"servizi.internazionalizzazione"}
                                defaultMessage={"INTERNAZIONALIZZAZIONE"}
                            />
                        </p>
                    </div>
                    <div onClick={() => popUp("valutazione azienda")} data-aos={"zoom-in"}>
                        <img src={icon5} alt={"valutazione d'azienda"} />
                        <p>
                            <FormattedMessage
                                id={"servizi.valutazione"}
                                defaultMessage={"VALUTAZIONE D'AZIENDA"}
                            />
                        </p>
                    </div>
                    <div onClick={() => popUp("consulenza fiscale")} data-aos={"zoom-in"}>
                        <img src={icon6} alt={"consulenza fiscale"} />
                        <p>
                            <FormattedMessage
                                id={"servizi.fiscale"}
                                defaultMessage={"CONSULENZA FISCALE"}
                            />
                        </p>
                    </div>
                    <div onClick={() => popUp("controllo gestione")} data-aos={"zoom-in"}>
                        <img src={icon7} alt={"controllo di gestione"} />
                        <p>
                            <FormattedMessage
                                id={"servizi.gestione"}
                                defaultMessage={"CONTROLLO DI GESTIONE"}
                            />
                        </p>
                    </div>
                    <div onClick={() => popUp("iva esteri")} data-aos={"zoom-in"}>
                        <img src={icon7} alt={"iva esteri"} />
                        <p>
                            <FormattedMessage
                                id={"servizi.ivaesteri"}
                                defaultMessage={"IVA SOGGETTI ESTERI"}
                            />
                        </p>
                    </div>
                    {props.lang === "en" &&
                        <div data-aos={"zoom-in"}>
                            <img src={icon8} alt={"xero certified"} />
                            <p>XERO CERTIFIED</p>
                        </div>
                    }
                    <div onClick={() => navigate('/servizi')} 
                    id={"servizi-online"} 
                    data-aos={"zoom-in"}
                    className={props.lang==='en' ? 'center-cell' : ''}
                    >
                        <p>
                            <FormattedMessage
                                id={"servizi.online"}
                                defaultMessage={"SERVIZI ONLINE"}
                            />
                        </p>
                    </div>

                </div>

                <hr style={{ width: "50%", margin: "2em auto" }} />

                <div>
                    {/* TrustBox widget - Micro Review Count */}
                    <div className="trustpilot-widget" data-locale="it-IT" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="613380b6aa94de001dabdd8d" data-style-height="24px" data-style-width="100%" data-theme="light">
                        <a href="https://it.trustpilot.com/review/studiomatera.eu" target="_blank" rel="noopener noreferrer">Trustpilot</a>
                    </div>
                </div>
            </div>
        </section>
    )
};


const PopUp = (props) => {
    let title = "";
    let text = "";
    let icon = "";

    switch (props.topic) {
        case ("consulenza familiare"):
            title = <FormattedMessage id={"s.familiare.title"} defaultMessage={"Consulenza familiare"} />;
            if (lang === "it") text = <>
                <p>
                    Lo Studio Andrea Matera offre consulenza familiare ad alto valore aggiunto.
                    In qualità di Family Office gestisce l’allocazione delle risorse economiche e finanziarie dei propri Clienti,
                    mediante la diversificazione, l’amministrazione e la protezione degli investimenti e di patrimoni familiari.
                </p>
                <p>
                    Inoltre, lo Studio Andrea Matera supporta i propri clienti, titolari di proprietà immobiliari,
                    nella gestione degli adempimenti fiscali e amministrativi occupandosi di:
                </p>
                <ul>
                    <li>Assistenza nella redazione dei contratti di locazione;</li>
                    <li>Assistenza nella redazione di tutte le variazioni contrattuali;</li>
                    <li>Gestione del rapporto amministrativo con gli affittuari.</li>
                </ul>
            </>;
            if (lang === "en") text = <>
                <p>
                    Studio Andrea Matera offers family advice with high added value.
                    As a Family Office, it manages the allocation of its Customers' economic and financial resources,
                    through diversification, administration and protection of investments and family assets.
                </p>
                <p>
                    In addition, Studio Andrea Matera supports its customers, owners of real estate properties,
                    in the management of tax and administrative obligations dealing with:
                </p>
                <ul>
                    <li>Assistance in drafting lease agreements;</li>
                    <li>Assistance in drafting contractual changes;</li>
                    <li>Management of the administrative relationship with the tenants.</li>
                </ul>
            </>;
            icon = icon1;
            break;
        case ("consulenza societaria"):
            title = <FormattedMessage id={"s.societaria.title"} defaultMessage={"Consulenza societaria"} />;
            if (lang === "it") text = <>
                <p>
                    Lo studio Andrea Matera offre consulenza societaria impostando, realizzando e monitorando quotidianamente
                    la gestione contabile, amministrativa e fiscale per artigiani, Professionisti e PMI occupandosi,
                    nello specifico, delle seguenti tematiche:
                </p>
                <ul>
                    <li>Consulenza sulle differenti forme giuridiche previste e individuazione della struttura giuridica migliore
                        per i Clienti, nazionale (S.n.c., S.a.s, S.r.l., S.p.a, S.a.p.a.) e internazionale di diritto inglese
                        (LTD, LLP) e olandese (VOF, BV, NV e Fondazioni-Stichting);</li>
                    <li>Costituzione di società commerciali, immobiliari e holding nazionali ed internazionali;</li>
                    <li>Consulenza e realizzazione dei patti parasociali;</li>
                    <li>Programmazione e pianificazione di passaggi generazionali;</li>
                    <li>Domiciliazioni societarie nazionali ed internazionali;</li>
                    <li>Consulenza pianificazione e introduzione di sistemi di controllo di gestione
                        per l’ottimizzazione dei risultati economici, finanziari e fiscali;</li>
                    <li>Assunzione di incarichi di sindaco e revisore;</li>
                    <li>Assunzione di incarichi di amministratore.</li>
                </ul>
            </>;
            if (lang === "en") text = <>
                <p>
                    The Andrea Matera studio offers corporate consultancy by setting up, realizing and monitoring daily
                    accounting, administrative and tax management for craftsmen, professionals and SMEs dealing with the following topics:
                </p>
                <ul>
                    <li>Consulting on different legal forms and identifying the best legal structure
                        for Customers, national (S.n.c., S.a.s, S.r.l., S.p.a, S.a.p.a.) and international under English law
                        (LTD, LLP) and Dutch (VOF, BV, NV and Stichting-Foundations);</li>
                    <li>Establishment of national and international commercial, real estate and holding companies;</li>
                    <li>Consultancy and implementation of shareholder agreements;</li>
                    <li>Programming and planning of generational changes;</li>
                    <li>National and international corporate domicile;</li>
                    <li>Consulting planning and introduction of management control systems
                        for the optimization of economic, financial and tax results;</li>
                    <li>Assumption of auditor position;</li>
                    <li>Taking on of directorships.</li>
                </ul>
            </>;
            icon = icon2;
            break;
        case ("operazioni straordinarie"):
            title = <FormattedMessage id={"s.straordinarie.title"} defaultMessage={"Operazioni straordinarie"} />;
            if (lang === "it") text = <>
                <p>
                    Lo Studio Andrea Matera supporta i propri clienti finalizzando e ottimizzando sotto l’aspetto economico,
                    patrimoniale, finanziario e fiscale le seguenti operazioni societarie:
                </p>
                <ul>
                    <li>Trasformazioni;</li>
                    <li>Fusioni;</li>
                    <li>Scissioni;</li>
                    <li>Conferimenti e affitti d'azienda.</li>
                </ul>
            </>;
            if (lang === "en") text = <>
                <p>
                    Studio Andrea Matera supports its customers by finalizing and optimizing from an economic point of view,
                    balance sheet, financial and tax, the following corporate transactions:
                </p>
                <ul>
                    <li>Transformations;.</li>
                    <li>Mergers;</li>
                    <li>Demergers;</li>
                    <li>Assignment and company sublease.</li>
                </ul>
            </>;
            icon = icon3;
            break;
        case ("internazionalizzazione"):
            title = <FormattedMessage id={"s.internazionalizzazione.title"} defaultMessage={"Internazionalizzazione"} />;
            if (lang === "it") text = <>
                <p>
                    Lo Studio Andrea Matera imposta, realizza e supporta i propri Clienti nella presenza nei mercati esteri mediante
                    la creazione di adeguati network logistici, produttivi e strutturali, grazie anche alla conoscenza
                    e collaborazione di importanti realtà economiche e finanziarie in Europa, U.S.A., Messico e Sud Est
                    Asiatico.
                </p>
            </>;
            if (lang === "en") text = <>
                <p>
                    Studio Andrea Matera sets up, builds and supports its Customers in their presence in foreign markets through
                    the creation of adequate logistic, production and structural networks, also thanks to knowledge
                    and collaboration of important economic and financial realities in Europe, U.S.A, Mexico and South East
                    Asian.
                </p>
            </>;
            icon = icon4;
            break;
        case ("valutazione azienda"):
            title = <FormattedMessage id={"s.valutazione.title"} defaultMessage={"Valutazione d'azienda"} />;
            if (lang === "it") text = <>
                <p>
                    Lo Studio Andrea Matera nel rispetto dell’applicazione dei principi italiani di valutazione (PIV) è
                    in grado di quantificare il “valore” di una specifica realtà economica grazie all’applicazione di
                    diversi modelli di valutazione tra i quali:
                </p>
                <ul>
                    <li>metodo reddituale;</li>
                    <li>metodo patrimoniale;</li>
                    <li>metodo misto;</li>
                    <li>metodo finanziario.</li>
                </ul>
            </>;
            if (lang === "en") text = <>
                <p>
                    Studio Andrea Matera respecting the application of the Italian evaluation principles (PIV) is
                    able to quantify the "value" of a specific economic reality thanks to the application of
                    various evaluation models including:
                </p>
                <ul>
                    <li>income method;</li>
                    <li>equity method;</li>
                    <li>mixed method;</li>
                    <li>financial method.</li>
                </ul>
            </>;
            icon = icon5;
            break;
        case ("consulenza fiscale"):
            title = <FormattedMessage id={"s.fiscale.title"} defaultMessage={"Consulenza fiscale"} />;
            if (lang === "it") text = <>
                <p>
                    Lo Studio Andrea Matera garantisce l’ottimizzazione fiscale ai propri Clienti - nazionali ed internazionali -
                    mediante un'accurata consulenza nonché la predisposizione e l'invio dei seguenti modelli dichiarativi:
                </p>
                <ul>
                    <li>
                        Modello Redditi per
                        <ul>
                            <li>Persone fisiche</li>
                            <li>Professionisti</li>
                            <li>Artigiani e commercianti</li>
                            <li>Società di persone</li>
                            <li>Società di capitali ed enti non commerciali</li>
                        </ul>
                    </li>
                    <li>Modelli 730</li>
                    <li>Liquidazioni IVA periodiche</li>
                    <li>Dichiarazioni IVA</li>
                    <li>Modelli 770</li>
                    <li>ISA</li>
                </ul>
            </>;
            if (lang === "en") text = <>
                <p>
                    Studio Andrea Matera guarantees tax optimization to its Clients - national and international -
                    through careful advice as well as the preparation and sending of the following declarative models:
                </p>
                <ul>
                    <li>
                        Income Model for
                        <ul>
                            <li>Physical persons</li>
                            <li>Professionals</li>
                            <li>Craftsmen and traders</li>
                            <li>Society of people</li>
                            <li>Capital companies and non-commercial entities</li>
                        </ul>
                    </li>
                    <li>730 models</li>
                    <li>Periodic VAT payments</li>
                    <li>VAT returns</li>
                    <li>770 models</li>
                    <li>ISA</li>
                </ul>
            </>;
            icon = icon6;
            break;
        case ("controllo gestione"):
            title = <FormattedMessage id={"s.gestione.title"} defaultMessage={"Controllo di gestione"} />;
            if (lang === "it") text = <>
                <p>
                    Lo Studio Andrea Matera è in grado di impostare ed introdurre in qualsiasi realtà economica
                    - nazionale e internazionale - adeguati sistemi di controllo di gestione volti a migliorare
                    significativamente l’area economica, finanziaria e patrimoniale dei propri Clienti.
                </p>
                <p>
                    Grazie all’introduzione di sistemi di budget e reporting, lo Studio Andrea Matera è in grado di supportare
                    i propri Clienti nella programmazione e pianificazione delle loro realtà imprenditoriali
                    raggiungendo in maniera efficace ed efficiente i loro obiettivi di breve, medio e lungo termine.
                </p>
            </>;
            if (lang === "en") text = <>
                <p>
                    Studio Andrea Matera is able to set up and introduce into any economic reality
                    - national and international - adequate management control systems aimed at improving
                    significantly the economic, financial and equity area of its Customers.
                </p>
                <p>
                    Thanks to the introduction of budget and reporting systems, the Studio Andrea Matera is able to support
                    their customers in the planning and planning of their business realities,
                    achieving their short, medium and long term goals effectively and efficiently.
                </p>
            </>;
            icon = icon7;
            break;
        case ("iva esteri"):
            title = <FormattedMessage id={"s.ivaesteri.title"} defaultMessage={"Iva Soggetti Esteri"} />;
            if (lang === "it") text = <>
                <p>
                    Per le <strong>realtà estere</strong> lo Studio Andrea Matera offre una valutazione sull’adozione della partita IVA e la predisposizione e invio dei seguenti modelli:
                </p>
                <ul>
                    <li>Identificazione IVA</li>
                    <li>Comunicazioni IVA trimestrali (LIPE)</li>
                    <li>Comunicazioni IVA annuali</li>
                    <li>Richiesta rimborsi IVA</li>
                </ul>
            </>;
            if (lang === "en") text = <>
                <p>
                    For <strong>foreign companies</strong>, an assessment on the adoption of the VAT number and the preparation and sending of the following models:
                </p>
                <ul>
                    <li>Italian VAT identification</li>
                    <li>Quarterly VAT Return (LIPE)</li>
                    <li>Annual Tax Return</li>
                    <li>VAT refund request</li>
                </ul>
            </>;
            icon = icon7;
            break;
        default:
            break;
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
                <button
                    className={"custom-btn"}
                    id={"preventivo-btn"}
                    onClick={() => window.location = "mailto:segreteria@studiomatera.eu"}
                    data-aos={"zoom-in"}
                >
                    <span><FormattedMessage
                        id={"contatti.preventivo"}
                        defaultMessage={"Richiedi un preventivo"}
                    /></span>
                </button>
                <div className={"modal-img-container"}>
                    <img src={icon} alt={""} />
                </div>
            </Modal.Body>

        </Modal>
    )
};

export default Servizi;