import React from "react";
import logo from "../logos/logo_studio_matera_Low.png";
import home from "../icons/up_arrow.png";
import { Link } from "react-scroll";

const Footer = () => {

    return (
        <div className={"footer"}>
            <div className={"footer-grid"}>
                <div>
                    <img src={logo} alt={"Logo"} />
                </div>
                <div id={"footer-details"}>
                    <p>P.IVA 02310360991</p>
                    <div>
                        <a href={"https://www.studiomatera.eu/cookies.html"} target={"_blank"} rel="noopener noreferrer" >Cookies</a>
                        <span> - </span>
                        <a href={"https://www.studiomatera.eu/privacy.html"} target={"_blank"} rel="noopener noreferrer">Privacy Policy</a>
                    </div>
                </div>
                <div>
                    <Link
                        to={"section-home"}
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={800}
                    >
                        <img src={home} alt={"Home"} id={"up-arrow"} />
                    </Link>
                </div>
            </div>
            <p>© Copyright 2020 Studio Matera</p>
        </div>
    )
};

export default Footer;