import React from "react";
import gps from "../icons/gps_dark.png";
import tel from "../icons/telefono_dark.png";
import telCol from "../icons/telefono_col.png";
import mail from "../icons/mail_dark.png";
import mailCol from "../icons/mail_col.png";
import algebraLogo from "../logos/algebra.png";
import algebraLogoOver from "../logos/algebra_over.png";
import {FormattedMessage} from "react-intl";

const DoveSiamo = () => {
    let addresses = [
        {
            id: 1,
            title: "Genova",
            line1: "C.so Carbonara, 5",
            line2: "16121 Genova, GE",
            line3: "Italy",
            tel: "+39 340 5869174",
            mail: "segreteria@studiomatera.eu",
            url: "https://goo.gl/maps/8PaFQ5jTRddt6gBCA",
        },
        {
            id: 2,
            title: "Rapallo",
            line1: "via Marsala, 2, int. 2",
            line2: "16035 Rapallo, GE",
            line3: "Italy",
            tel: "+39 340 5869174",
            mail: "segreteria@studiomatera.eu",
            url: "https://maps.app.goo.gl/FmXxdv1pPDmCAqcdA",
        },
        {
            id: 3,
            title: "London",
            line1: "Seagrave Road, 9",
            line2: "SW6 1RP, Londra",
            line3: "United Kingdom",
            tel: "+39 340 5869174",
            mail: "segreteria@studiomatera.eu",
            url: "https://goo.gl/maps/ZL7EUeaoq81mv8ZC8",
        }
    ];

    return (
        <section className={"container"}>
            <div className={"section-header"}>
                <p>
                    <FormattedMessage
                        id={"dovesiamo.title"}
                        defaultMessage={"DOVE SIAMO"}
                    />
                </p>
                <div/>
            </div>
            <div className={"section-content"}>
                <div id={"dovesiamo-grid"}>
                    {
                        addresses.map(a =>
                        {
                            if (a.id!==4)
                                return (
                                    <div className={"address-grid-container"}>
                                    <div className={"address-grid"} id={"address"+a.id} data-aos={"fade-down"}>
                                        <h3>{a.title}</h3>
                                        <span><img src={gps} alt={"in"}/></span>
                                        <p>{a.line1}<br/>{a.line2}<br/>{a.line3}</p>
                                        <span>
                                    <img
                                        src={tel}
                                        alt={"tel"}
                                        id={"address-tel"+a.id}
                                        style={{cursor: "pointer"}}
                                        onMouseOver={e => e.target.src = telCol}
                                        onMouseLeave={e => e.target.src = tel}
                                        onClick={() => window.location = "tel:" + a.tel}
                                    />
                                </span>
                                        <p
                                            style={{cursor: "pointer"}}
                                            onMouseOver={() => document.getElementById("address-tel"+a.id).src = telCol}
                                            onMouseLeave={() => document.getElementById("address-tel"+a.id).src = tel}
                                            onClick={() => window.location = "tel:" + a.tel}
                                        >
                                            {a.tel}
                                        </p>
                                        <span>
                                    <img
                                        src={mail}
                                        alt={"mail"}
                                        id={"address-mail"+a.id}
                                        style={{cursor: "pointer"}}
                                        onMouseOver={e => e.target.src = mailCol}
                                        onMouseLeave={e => e.target.src = mail}
                                        onClick={() => window.location = "mailto:" + a.mail}
                                    />
                                </span>
                                        <p
                                            className={"mail-address"}
                                            style={{cursor: "pointer"}}
                                            onMouseOver={() => document.getElementById("address-mail"+a.id).src = mailCol}
                                            onMouseLeave={() => document.getElementById("address-mail"+a.id).src = mail}
                                            onClick={() => window.location = "mailto:" + a.mail}
                                        >
                                            {a.mail}
                                        </p>
                                        <button className={"custom-btn"} onClick={() => window.open(a.url)}>
                                            <FormattedMessage
                                                id={"dovesiamo.comearrivare"}
                                                defaultMessage={"come arrivare"}
                                            />
                                        </button>
                                    </div>
                                    </div>
                                );
                            else return (
                                <div className={"address-grid"} id={"address"+a.id} data-aos={"fade-down"}>
                                    <h3>{a.title}</h3>
                                    <span><img src={gps} alt={"in"}/></span>
                                    <p>{a.line1}<br/>{a.line2}<br/>{a.line3}</p>

                                    <span>
                                        <img
                                            src={mail}
                                            alt={"mail"}
                                            id={"address-mail"+a.id}
                                            style={{cursor: "pointer"}}
                                            onMouseOver={e => e.target.src = mailCol}
                                            onMouseLeave={e => e.target.src = mail}
                                            onClick={() => window.location = "mailto:" + a.mail}
                                        />
                                    </span>
                                    <p
                                        className={"mail-address"}
                                        style={{cursor: "pointer"}}
                                        onMouseOver={() => document.getElementById("address-mail"+a.id).src = mailCol}
                                        onMouseLeave={() => document.getElementById("address-mail"+a.id).src = mail}
                                        onClick={() => window.location = "mailto:" + a.mail}
                                    >
                                        {a.mail}
                                    </p>

                                    <p style={{gridColumnEnd: "span 2", "font-size": "14pt", textAlign: "center"}}>
                                        <FormattedMessage
                                            id={"dovesiamo.algebra"}
                                            defaultMessage={"In collaborazione con"}
                                        />
                                    </p>

                                    <button className={"custom-btn"} onClick={() => window.open(a.url)} onMouseOver={() => document.getElementById("algebra-img").src = algebraLogoOver} onMouseOut={() => document.getElementById("algebra-img").src = algebraLogo}>
                                        <img src={algebraLogo} alt={"algebra"} style={{height: "1.4em"}} id={"algebra-img"} />
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
};

export default DoveSiamo;