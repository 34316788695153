import React, { useState } from 'react';
import "../purecookie/purecookie.css";

import ReactGA from 'react-ga'

const Cookies = (props) => {
    const [minimize, setMinimize] = useState(localStorage.getItem('useCookies') === 'true');

    React.useEffect(() => {
        fadeIn("cookieConsentContainer");
        if (!minimize) window['ga-disable-UA-186905760-2'] = true;
        else {
            ReactGA.initialize('UA-186905760-2');
            ReactGA.pageview(window.location.pathname)
        }
    });

    const handleCookies = (choice) => {
        localStorage.setItem('useCookies', choice);
        if (choice === false)
            window['ga-disable-UA-186905760-2'] = true;
        else {
            ReactGA.initialize('UA-186905760-2');
            ReactGA.pageview(window.location.pathname)
            window['ga-disable-UA-186905760-2'] = false;
        }
        setMinimize(true);
    }

    if (minimize) {
        return (
            <div className={`cookieConsentContainer ${minimize ? 'minimized' : ''}`} id={"cookieConsentContainer"}
                onClick={() => setMinimize(false)} style={{ cursor: 'pointer', maxWidth: '120px', padding: '10px' }}>
                <div className={"cookieTitle"}>
                    <p style={{ fontSize: '12pt' }}>Cookies</p>
                </div>
            </div>
        )
    }
    else
        if (props.lang === "it")
            return (
                <div className={`cookieConsentContainer ${minimize ? 'minimized' : ''}`} id={"cookieConsentContainer"}>
                    <div className={"cookieTitle"}>
                        <p>Cookies</p>
                    </div>
                    <div className={"cookieDesc"}>
                        <p>
                            Utilizziamo i cookies per migliorare la tua esperienza d'uso del sito e per analizzare il traffico. Cliccando su "Accetto", consenti l'uso dei cookies.
                        </p>
                        <p>
                            <a href={"https://www.studiomatera.eu/cookies.html"} target={"_blank"} rel={"noopener noreferrer"}>Per cosa?</a> - <a href={"https://www.studiomatera.eu/privacy.html"} target={"_blank"} rel={"noopener noreferrer"}>Privacy Policy</a>
                        </p>
                    </div>
                    <div className={"cookieButtons"} style={{textAlign: 'right' }}>
                        <button onClick={() => handleCookies(false)} style={{ backgroundColor: 'var(--variant-color)' }}>Rifiuto</button>
                        <button onClick={() => handleCookies(true)} >Accetto</button>
                    </div>
                </div>
            );
        else
            return (
                <div className={`cookieConsentContainer ${minimize ? 'minimized' : ''}`} id={"cookieConsentContainer"}>
                    <div className={"cookieTitle"}>
                        <p>Cookies</p>
                    </div>
                    <div className={"cookieDesc"}>
                        <p>
                            We use cookies to improve your experience and to analyze traffic. By clicking "Accept," you consent to the use of cookies.
                        </p>
                        <p>
                            <a href={"https://www.studiomatera.eu/cookies.html"} target={"_blank"} rel={"noopener noreferrer"}>What for?</a> - <a href={"https://www.studiomatera.eu/privacy.html"} target={"_blank"} rel={"noopener noreferrer"}>Privacy Policy</a>
                        </p>
                    </div>
                    <div className={"cookieButtons"} style={{textAlign: 'right' }}>
                        <button onClick={() => handleCookies(false)} style={{ backgroundColor: 'var(--variant-color)', color: 'white' }}>Reject</button>
                        <button onClick={() => handleCookies(true)} >Accept</button>
                    </div>
                </div>
            );
};

export default Cookies;


function fadeIn(elem) {
    let el = document.getElementById(elem);
    el.style.opacity = 0;
    el.style.display = "block";

    (function fade() {
        let val = parseFloat(el.style.opacity);
        if (!((val += .02) > 1)) {
            el.style.opacity = val;
            requestAnimationFrame(fade);
        }
    })();
}