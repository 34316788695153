import React from "react";
import { useRouteError, Link } from "react-router-dom";
import logo from "../logos/logo_studio_matera_Low.png";

export default function () {
    const error = useRouteError();
    return (
        <div id="error-page">
            <img src={logo} alt={"logo studio matera"} />
            <h1>Oops!</h1>
            <p>Mi dispiace, purtroppo si è verificato un errore.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
            <Link to={'/'}>Torna alla home</Link>
        </div>
    )
}