import React from "react";
import { useCalendlyEventListener, PopupModal } from "react-calendly";
import emailjs from '@emailjs/browser';
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Calendly = () => {

    const navigate = useNavigate();
    const CAL_TOKEN = 'eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjg0MTYyNjUzLCJqdGkiOiJmNDYzOWJmMi1iZGYyLTRjZDYtOGQ1MC0yMTk4MjMwYmIxZjUiLCJ1c2VyX3V1aWQiOiIyN2Y5MmVhZS1mNjlhLTQ3NWItODRlMi1lNmY5YWI1MDE3ZDgifQ.f7glHwGYXFCsFN9YWk0doYZKKN3PSFs6jRwEqasbMwZjkx7_gOTM7ESW5zqC2cjoI28Cf9tlOtS12Y2RYBd13Q';

    async function composeInvoice(eventUri, inviteeUri) {
        let eventParams = {};

        const calConfig = {
            headers: {
                'Authorization': `Bearer ${CAL_TOKEN}`
            }
        }

        await axios.get(eventUri, calConfig)
            .then(res => {
                //console.log(res.data.resource)
                eventParams = {
                    ...eventParams,
                    start_date: moment(res.data.resource.start_time).format('D/MM/YYYY'),
                    start_time: moment(res.data.resource.start_time).format('HH:mm'),
                }
            })

        await axios.get(inviteeUri, calConfig)
            .then(res => {
                //console.log(res.data.resource)
                eventParams = {
                    ...eventParams,
                    invitee_email: res.data.resource.email,
                    invitee_name: res.data.resource.name,
                }
            })

        // await axios.post('https://api-m.paypal.com/v1/oauth2/token', 'grant_type=client_credentials', {
        //     auth: {
        //         username: PP_USER,
        //         password: PP_PSW
        //     },
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded'
        //     }
        // }).then(res => {
        //     PP_TOKEN = res.data.access_token
        // })


        // /* RESPONSE
        // {
        //     "scope": "https://uri.paypal.com/services/checkout/one-click-with-merchant-issued-token https://uri.paypal.com/services/invoicing https://uri.paypal.com/services/disputes/read-buyer https://uri.paypal.com/services/payments/realtimepayment https://uri.paypal.com/services/disputes/update-seller https://uri.paypal.com/services/payments/payment/authcapture openid https://uri.paypal.com/services/disputes/read-seller https://uri.paypal.com/services/payments/refund https://api.paypal.com/v1/vault/credit-card https://uri.paypal.com/services/billing-agreements https://api.paypal.com/v1/payments/.* https://uri.paypal.com/payments/payouts https://api.paypal.com/v1/vault/credit-card/.* https://uri.paypal.com/services/shipping/trackers/readwrite https://uri.paypal.com/services/subscriptions https://uri.paypal.com/services/applications/webhooks",
        //     "access_token": "A21AAKxjRx-lO4XhbVJJNejd5s5-IwZPJeLx8sulvsPXrjKadyAV5GTDVpVZYHdROZaNjwu9Kz46YK3WxdxS0alLs_1b7e11Q",
        //     "token_type": "Bearer",
        //     "app_id": "APP-80W284485P519543T",
        //     "expires_in": 32400,
        //     "nonce": "2023-02-25T16:21:26ZAut-CcZZLLcZI8EDz2rn8edp0Rktfy4TsLsmalaNpGo"
        // }
        // */

        // const ppConfig = {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': `Bearer ${PP_TOKEN}`
        //     }
        // }

        // let invoiceNumber;

        // await axios.post('https://api-m.paypal.com/v2/invoicing/generate-next-invoice-number', {}, ppConfig)
        //     .then(res => {
        //         invoiceNumber = res.data.invoice_number
        //     })


        // /* RESPONSE
        // {
        //     "data": {
        //         "invoice_number": "0001"
        //     },
        //     "status": 200,
        //         "statusText": "",
        //             "headers": {
        //         "cache-control": "max-age=0, no-cache, no-store, must-revalidate",
        //             "content-type": "application/json"
        //     },
        //     "config": {
        //         "url": "https://api-m.sandbox.paypal.com/v2/invoicing/generate-next-invoice-number",
        //             "method": "post",
        //                 "data": "{}",
        //                     "headers": {
        //             "Accept": "application/json, text/plain",
        //     "Content-Type": "application/json",
        //         "Authorization": "Bearer A21AAKxjRx-lO4XhbVJJNejd5s5-IwZPJeLx8sulvsPXrjKadyAV5GTDVpVZYHdROZaNjwu9Kz46YK3WxdxS0alLs_1b7e11Q"
        //     },
        //     "transformRequest": [
        //         null
        //     ],
        //         "transformResponse": [
        //             null
        //         ],
        //             "timeout": 0,
        //                 "xsrfCookieName": "XSRF-TOKEN",
        //                     "xsrfHeaderName": "X-XSRF-TOKEN",
        //                         "maxContentLength": -1
        // },
        // "request": { }
        // }
        // */

        // const invoiceData = {
        //     "detail": {
        //         "invoice_number": invoiceNumber,
        //         "reference": "deal-ref",
        //         "invoice_date": moment().format('YYYY-MM-DD'),
        //         "currency_code": lang==='it' ? "EUR" : "GBP",
        //         "payment_term": {
        //             "due_date": moment().format('YYYY-MM-DD')
        //         }
        //     },
        //     "invoicer": {
        //         "name": {
        //             "given_name": "Andrea",
        //             "surname": "Matera"
        //         },
        //         "address": {
        //             "address_line_1": "Piazza Venezia, 38 int.1",
        //             "admin_area_2": "Rapallo",
        //             "admin_area_1": "GE",
        //             "postal_code": "16035",
        //             "country_code": "IT"
        //         },
        //         "email_address": "marzio98@live.com",
        //         "phones": [
        //             {
        //                 "country_code": "39",
        //                 "national_number": "3405869174",
        //                 "phone_type": "MOBILE"
        //             }
        //         ],
        //         "website": "www.studiomatera.eu",
        //         "logo_url": 'https://www.studiomatera.eu/og/logo_matera-low.png',
        //     },
        //     "primary_recipients": [
        //         {
        //             "billing_info": {
        //                 "name": {
        //                     "given_name": eventParams.invitee_name
        //                 },
        //                 "email_address": eventParams.invitee_email,
        //             },
        //         }
        //     ],
        //     "items": [
        //         {
        //             "name": "Consulenza Studio Andrea Matera",
        //             "quantity": "1",
        //             "unit_amount": {
        //                 "currency_code": lang==='it' ? "EUR" : "GBP",
        //                 "value": lang==='it' ? "90" : "80"
        //             },
        //             "unit_of_measure": "QUANTITY"
        //         },
        //     ],
        //     "configuration": {
        //         "partial_payment": {
        //             "allow_partial_payment": false,
        //         },
        //         "allow_tip": true,
        //         "tax_calculated_after_discount": false,
        //         "tax_inclusive": false,
        //     },
        // }

        // let invoiceUrl;

        // await axios.post('https://api-m.paypal.com/v2/invoicing/invoices', invoiceData, ppConfig)
        //     .then(res => {
        //         invoiceUrl = res.data.href;
        //     })

        // await axios.get('https://api-m.paypal.com/v1/identity/oauth2/userinfo?schema=paypalv1.1', ppConfig)
        //     .then(res => {
        //         console.log(res.data)
        //     })

        // await axios.get('https://api-m.paypal.com/v2/invoicing/invoices?total_required=true', ppConfig)
        //     .then(res => {
        //         console.log(res.data)
        //     })

        // await axios.post(`${invoiceUrl}/send`, {
        //     "send_to_invoicer": true,
        //     "send_to_recipient": true
        // }, ppConfig)
        //     .then(res => {
        //         console.log(res.data)
        //     })

        emailjs.send('service_zhr9idg', 'template_mnrgxox', eventParams)
            .then(function (response) {
                console.log('Email succesfully sent!', response.status, response.text);
            }, function (error) {
                console.log('Email failed', error);
            });

    }

    useCalendlyEventListener({
        onEventScheduled: (e) => {

            composeInvoice(e.data.payload.event.uri, e.data.payload.invitee.uri)

            /* RESPONSE
            {
                "event": "calendly.event_scheduled",
                "payload": {
                    "event": {
                        "uri": "https://api.calendly.com/scheduled_events/677d9105-9daa-4010-b61f-da726466e940"
                    },
                    "invitee": {
                        "uri": "https://api.calendly.com/scheduled_events/677d9105-9daa-4010-b61f-da726466e940/invitees/3498cd12-e01e-4e1a-8f34-7fc4c151ba11"
                    }
                }
            }
            */

            /* EVENT EXAMPLE
            {
                "calendar_event": null,
                "created_at": "2023-02-25T12:51:26.470840Z",
                "end_time": "2023-02-27T08:40:00.000000Z",
                "event_guests": [],
                "event_memberships": [
                    {
                        "user": "https://api.calendly.com/users/7ca3e061-d99a-47fb-a809-2aa70bb256c3"
                    }
                ],
                "event_type": "https://api.calendly.com/event_types/70bc3f6f-5f62-4e66-8e73-7021aecae1b3",
                "invitees_counter": {
                    "active": 1,
                    "limit": 1,
                    "total": 1
                },
                "location": {
                    "data": {
                        "id": 87953028306,
                        "settings": {},
                        "password": "M32qz2",
                        "extra": null
                    },
                    "join_url": "https://us05web.zoom.us/j/87953028306?pwd=MU8zc29iNVR5MWVYV3hwYjRpZk5uZz09",
                    "status": "pushed",
                    "type": "zoom"
                },
                "name": "Consulenza Andrea Matera",
                "start_time": "2023-02-27T08:00:00.000000Z",
                "status": "active",
                "updated_at": "2023-02-25T12:51:26.470840Z",
                "uri": "https://api.calendly.com/scheduled_events/677d9105-9daa-4010-b61f-da726466e940"
            }
            */

            /* INVETEE EXAMPLE
            {
                "cancel_url": "https://calendly.com/cancellations/3498cd12-e01e-4e1a-8f34-7fc4c151ba11",
                "created_at": "2023-02-25T12:51:26.482937Z",
                "email": "marzio98@live.com",
                "event": "https://api.calendly.com/scheduled_events/677d9105-9daa-4010-b61f-da726466e940",
                "first_name": null,
                "last_name": null,
                "name": "Marzio Del Sole",
                "new_invitee": null,
                "no_show": null,
                "old_invitee": null,
                "payment": null,
                "questions_and_answers": [
                    {
                        "answer": "Ciao a tuttii",
                        "position": 0,
                        "question": "Per favore, condividi tutto ciò che può essere utile per preparare il nostro incontro."
                    }
                ],
                "reconfirmation": null,
                "reschedule_url": "https://calendly.com/reschedulings/3498cd12-e01e-4e1a-8f34-7fc4c151ba11",
                "rescheduled": false,
                "routing_form_submission": null,
                "status": "active",
                "text_reminder_number": null,
                "timezone": "Europe/Berlin",
                "tracking": {
                    "utm_campaign": null,
                    "utm_source": null,
                    "utm_medium": null,
                    "utm_content": null,
                    "utm_term": null,
                    "salesforce_uuid": null
                },
                "updated_at": "2023-02-25T12:51:26.482937Z",
                "uri": "https://api.calendly.com/scheduled_events/677d9105-9daa-4010-b61f-da726466e940/invitees/3498cd12-e01e-4e1a-8f34-7fc4c151ba11"
            }
            */

            /*
            emailjs.send('service_zhr9idg', 'template_mnrgxox')
                .then(function (response) {
                    console.log('Email succesfully sent!', response.status, response.text);
                }, function (error) {
                    console.log('Email failed', error);
                });
                */
        },
    });

    return (
        <PopupModal
            url="https://calendly.com/studiomatera/consulenza"
            onModalClose={() => navigate('/')}
            open={true}
            rootElement={document.getElementById("root")}
        />
    )
};

export default Calendly;