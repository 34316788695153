import React from "react";
import prof from "../imgs/andrea_matera_alpha.png";
import accaAward from "../imgs/acca_award_matera.jpg"
import londonBS from "../imgs/london_business_school.png"
import { FormattedMessage } from "react-intl";

const Professionista = () => {

    return (
        <section className={"container"}>
            <div className={"section-header"}>
                <p>
                    <FormattedMessage
                        id={"professionista.title"}
                        defaultMessage={"IL PROFESSIONISTA"}
                    />
                </p>
                <div />
            </div>
            <div className={"section-content"}>
                <div id={"il-professionista"}>
                    <div>
                        <div>
                            <div class={"professionista-photo-container"} data-aos={"fade-right"}>
                                <div>
                                    <img src={prof} alt={"Andrea Matera"} />
                                </div>
                            </div>
                            <div class={"professionista-tag"} data-aos={"fade-right"}>
                                <h2>Andrea Matera</h2>
                                <h3>Founder and Managing partner</h3>
                            </div>
                        </div>
                        <div>
                            <div class={"professionista-photo-container bio-photo"}>
                                <div>
                                    <img src={accaAward} alt={"Acca Awards"} />
                                </div>
                            </div>
                            <div class={"professionista-tag bio-photo-caption"} data-aos={"fade-right"}>
                                <h3>
                                    <FormattedMessage
                                        id={"professionista.accaAwards"}
                                        defaultMessage={"Premiazione ACCA quale miglior ordine professionale agli International Accounting Awards"}
                                    /></h3>
                            </div>
                        </div>
                        <div>
                            <div class={"professionista-photo-container bio-photo small"}>
                                <div>
                                    <img src={londonBS} alt={"London Business School"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"il-professionista-paragraphs"}>
                        <p>
                            <FormattedMessage
                                id={"professionista.paragraph1"}
                                defaultMessage={"Nato a Genova nel 1989, Andrea si è laureato in Economia Aziendale nel 2011 e in Amministrazione, Finanza e Controllo nel 2013 presso l’Università di Genova con una votazione finale di 110/110 e lode."}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={"professionista.paragraph2"}
                                defaultMessage={"Ha conseguito un Master in controllo di gestione presso la scuola di formazione IPSOA (2014) e successivamente, alla prima sessione disponibile(2015), si è diplomato Dottore Commercialista."}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={"professionista.paragraph3"}
                                defaultMessage={"Nel 2016 ha ottenuto l’abilitazione alla professione nel Regno Unito iscrivendosi presso l’Institute of Chartered Accountant of England and Wales (ICAEW). Nel 2017 è diventato Revisore Legale, mentre nel 2018 ha conseguito il diploma presso la SAF sui principi contabili e di valutazione. Infine, nel 2019 ha conseguito un Master in Family Office presso l’AIFO di Milano."}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={"professionista.paragraph4"}
                                defaultMessage={"Nel 2019 ha conseguito in Master in Family Office presso l’AIFO di Milano, mentre nel 2021 ha conseguito il diploma in Business and Finance Professional rilasciato dall’ICAEW nonché il riconoscimento internazionale ACCA Global rilasciato dall’Association of Certified Chartered Accountant."}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={"professionista.paragraph5"}
                                defaultMessage={"Andrea ha maturato una significativa esperienza nella consulenza fiscale, nella consulenza strategica alle imprese e nell’internazionalizzazione fiscale e aziendale."}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={"professionista.paragraph6"}
                                defaultMessage={"Nel 2022 ha ricevuto un importante riconoscimento professionale venendo citato da Forbes Italia tra i 100 migliori studi professionali del Paese. Sempre nel 2022 è stato iscritto presso l'Albo dei Consulenti Tecnici di Ufficio del Tribunale di Genova."}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={"professionista.paragraph7"}
                                defaultMessage={"Nel 2023 ha participate come finalista agli International Accounting Forum and Awards, prestigioso evento che riunisce rappresentanti di tutto il mondo operanti nel sistema contabile e consulenziale, nella categoria Young Accountant of the Year.\nSempre nel 2023 è stato nominato vincitore agli UK Business Awards nella categoria Tax Advisory Firm of the Year.\nNel dicembre 2023 è stato ammesso alla prestigiosa London Business School quale studente del master in Developing Strategy for Value."}
                            />
                        </p>
                    </div>
                </div>
                <div className={"quotation"} data-aos={"zoom-out"}>
                    <h2>"Faber est suae quisque fortunae"</h2>
                    <p>- Appio Claudio Cieco </p>
                </div>
            </div>
        </section>
    )
};

export default Professionista;