import React, { useState } from 'react';
import { Container, Nav, Navbar } from "react-bootstrap";
import logoA from "../logos/logo_studio_matera_Low.png";
import logoB from "../logos/logo_studio_matera_2023.png";
import { Link } from "react-scroll";
import { FormattedMessage } from "react-intl";
import itFlag from "../icons/it-lang.png";
import enFlag from "../icons/en-lang.png";

const NavHeader = (props) => {

    const [navbarType, setNavbarType] = useState("A");
    // window.addEventListener("scroll", () => {
    //     if (window.scrollY / document.body.scrollHeight < 0.3) setNavbarType("A")
    //     else setNavbarType("B")
    // });

    setInterval(() => {
        if (navbarType === "A") setNavbarType("B")
        else setNavbarType("A")
    },5000)

    return (
        <Navbar className={"custom-navbar navbar-dark " + (navbarType === "B" ? "navbar-B" : "")} fixed="top" expand="lg">
            <Container>
                <Navbar.Brand href="#home">
                    <Link
                        to={"section-home"}
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                    >

                        <img src={logoA} className="d-inline-block align-top header-logo" style={navbarType === "B" ? { opacity: 0 } : null} alt="LOGO" />
                        <img src={logoB} className="d-inline-block align-top header-logo" style={navbarType === "A" ? { opacity: 0 } : null} alt="LOGO" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" >
                    <Nav className="ml-auto custom-nav">
                        <Nav.Link
                            as={Link}
                            to={"section-home"}
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                        >
                            <FormattedMessage
                                id={"nav.home"}
                                defaultMessage={"HOME"}
                            />
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to={"section-chi-siamo"}
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                        >
                            <FormattedMessage
                                id={"nav.chisiamo"}
                                defaultMessage={"CHI SIAMO"}
                            />
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to={"section-servizi"}
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                        >
                            <FormattedMessage
                                id={"nav.servizi"}
                                defaultMessage={"I SERVIZI"}
                            />
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to={"section-professionista"}
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                        >
                            <FormattedMessage
                                id={"nav.professionista"}
                                defaultMessage={"IL PROFESSIONISTA"}
                            />
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to={"section-dove-siamo"}
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                        >
                            <FormattedMessage
                                id={"nav.dovesiamo"}
                                defaultMessage={"DOVE SIAMO"}
                            />
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to={"section-contatti"}
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                        >
                            <FormattedMessage
                                id={"nav.contatti"}
                                defaultMessage={"CONTATTI"}
                            />
                        </Nav.Link>
                        <div className={"language-toggle"}>
                            {props.lang === "en" && <img src={itFlag} alt={"it"} onClick={() => props.setLang("it")} />}
                            {props.lang === "it" && <img src={enFlag} alt={"en"} onClick={() => props.setLang("en")} />}
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
};

export default NavHeader;