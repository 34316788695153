import React from "react";
import {FormattedMessage} from "react-intl";

const Divisore3 = () => {

    return (
        <section className={"divider"}>
            <div className={"container"}>
                <div className={"quotation"} id={"quote-matera"} data-aos={"zoom-out"}>
                    <h2>
                        "
                        <FormattedMessage
                            id={"div3.quote"}
                            defaultMessage={"Il nostro successo risiede nella fiducia riposta in noi dai Clienti"}
                        />
                        "
                    </h2>
                    <p>- Andrea Matera </p>
                </div>
            </div>
        </section>
    )
};

export default Divisore3;