import React, {useState} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavHeader from "./sections/NavHeader";
import Header from "./sections/Header";
import ChiSiamo from "./sections/ChiSiamo";
import Servizi from "./sections/Servizi";
import Divisore1 from "./sections/Divisore1";
import Professionista from "./sections/Professionista";
import Loghi from "./sections/Loghi";
import Divisore3 from "./sections/Divisore3";
import DoveSiamo from "./sections/DoveSiamo";
import Contattaci from "./sections/Contattaci";
import ContactForm from "./sections/ContactForm";
import Footer from "./sections/Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Italian from "./languages/it.json";
import English from "./languages/en.json";
import {IntlProvider} from "react-intl";
import Cookies from './sections/Cookies';
import { Outlet } from 'react-router-dom';
import FAB from './sections/FAB';

AOS.init({
    once: true,
});


function App() {
    const [lang,setLang] = useState("it");
    let language;
    if (lang === "it") language=Italian;
    else if (lang === "en") language=English;

    return (
        <>
            <IntlProvider locale={navigator.language} messages={language}>
                <section id={"section-home"}>
                    <Header lang={lang}/>
                </section>
                <NavHeader lang={lang} setLang={setLang}/>
                <section id={"section-chi-siamo"}>
                    <ChiSiamo/>
                </section>
                <section id={"section-servizi"}>
                    <Servizi lang={lang}/>
                    <Divisore1 lang={lang}/>
                </section>
                <section id={"section-professionista"}>
                    <Professionista/>
                    <Loghi/>
                </section>
                <section id={"section-dove-siamo"}>
                    <DoveSiamo/>
                </section>
                <Divisore3/>
                {<section id={"section-contatti"}>
                    <Contattaci/>
                    <ContactForm lang={lang}/>
                </section>}
                <Footer/>
                <FAB />
                <Cookies lang={lang}/>
                <Outlet/>
                <div className={"background"}/>
            </IntlProvider>
        </>
    );
}

export default App;
