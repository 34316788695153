import React, {useState} from "react";
import {Carousel} from "react-bootstrap";
import slide1 from "../imgs/genova.jpg";
import slide2 from "../imgs/rapallo.jpg";
import slide3 from "../imgs/londra.jpg";



const Header = (props) => {
    const itSub = ["Dottore Commercialista", "Revisore Legale", "Family Office"];
    const enSub = ["Chartered Accountant", "Statutory Auditor", "Family Office"];
    let initSub;
    if (props.lang==="it") initSub = itSub[0];
    else if (props.lang==="en") initSub = enSub[0];
    const [subtitle,setSubtitle] = useState(initSub);

    const images = [slide1,slide2,slide3];
    images.forEach((picture) => { new Image().src = picture.fileName });


    function cycleSubtitle() {
        if (props.lang==="it") {
            let index = itSub.indexOf(subtitle);
            if (index < itSub.length-1) setSubtitle(itSub[index+1]);
            else setSubtitle(itSub[0]);
        }
        else if (props.lang==="en") {
            let index = enSub.indexOf(subtitle);
            if (index < enSub.length-1) setSubtitle(enSub[index+1]);
            else setSubtitle(enSub[0]);
        }
        else {
            console.log(props.lang);
        }
    }
    return (
        <div>
            <div className={"custom-header"}>
                <h1>STUDIO ANDREA MATERA</h1>
                <h2>{subtitle}</h2>
            </div>
            <Slideshow cycleSubtitle={cycleSubtitle}/>
        </div>
    )
};

const Slideshow = (props) => {
    return (
        <Carousel className={"slideshow"} controls={false} pause={false} onSlid={() => props.cycleSubtitle()}>
            <Carousel.Item style={{backgroundImage: `url(${slide1})`}}/>
            <Carousel.Item style={{backgroundImage: `url(${slide2})`}}/>
            <Carousel.Item style={{backgroundImage: `url(${slide3})`}}/>
        </Carousel>
    )
};

export default Header;