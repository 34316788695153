import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import { CalendarCheck } from "phosphor-react";
import { useNavigate } from "react-router-dom";

const ServiziOnline = () => {
    const [showService1, setShowService1] = useState(false);
    const [showService2, setShowService2] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            <Service1 show={showService1} onHide={() => setShowService1(false)}/>
            <Service2 show={showService2} onHide={() => setShowService2(false)}/>
            <Modal
                show = {true}
                onHide={()=>navigate('/')}
                size={"lg"}
                aria-labelledby={"contained-modal-title-vcenter"}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage
                            id={"s.online.title"}
                            defaultMessage={"Servizi online"}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body class={"servizi-online-body"}>
                    <div>
                        <p>
                            <FormattedMessage
                                id={"s.online.pagamento"}
                                defaultMessage={"A pagamento:"}
                            />
                        </p>
                        <button
                            className={"custom-btn servizi-btn"}
                            onClick={() => {
                                setShowService1(true);
                            }}
                        >
                            <FormattedMessage
                                id={"s.online.catastale.title"}
                                defaultMessage={"Visura catastale"}
                            />
                        </button>
                        <button
                            className={"custom-btn servizi-btn"}
                            onClick={() => {
                                setShowService2(true);
                            }}
                        >
                            <FormattedMessage
                                id={"s.online.camerale.title"}
                                defaultMessage={"Visura Camerale"}
                            />
                        </button>
                        <button
                            className={"custom-btn servizi-btn"} id={"servizi-consulenza-btn"}
                            onClick={() =>  navigate('/prenota')}
                        >
                            <FormattedMessage
                                id={"s.online.consulenza.title"}
                                defaultMessage={"Consulenza su ZOOM"}
                            />
                            <CalendarCheck size={24} weight={'bold'}/>
                        </button>
                    </div>
                    <div>
                        <p>
                            <FormattedMessage
                                id={"s.online.gratuiti"}
                                defaultMessage={"Gratuiti:"}
                            />
                        </p>
                        <button
                            className={"custom-btn servizi-btn"}
                            onClick={() => window.open("https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf")}
                        >
                            <FormattedMessage
                                id={"s.online.codicefiscale"}
                                defaultMessage={"Verifica Codice Fiscale"}
                            />
                        </button>
                        <button
                            className={"custom-btn servizi-btn"}
                            onClick={() => window.open("https://telematici.agenziaentrate.gov.it/VerificaPIVA/Scegli.do?parameter=verificaPiva")}
                        >
                            <FormattedMessage
                                id={"s.online.partitaIVA"}
                                defaultMessage={"Verifica Partita IVA"}
                            />
                        </button>
                        <button
                            className={"custom-btn servizi-btn"}
                            onClick={() => window.open("https://ec.europa.eu/taxation_customs/vies/?locale=it")}
                        >
                            <FormattedMessage
                                id={"s.online.iscrizioneVIES"}
                                defaultMessage={"Verifica Iscrizione al VIES"}
                            />
                        </button>
                        <button
                            className={"custom-btn servizi-btn"}
                            onClick={() => window.open("https://serviziweb.datev.it/wa")}
                        >
                            <FormattedMessage
                                id={"s.online.clienti"}
                                defaultMessage={"Servizi contabili e Gestionali Clienti"}
                            />
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
};

const Service1 = (props) => {
    return (
        <Modal
            {...props}
            size={"lg"}
            aria-labelledby={"contained-modal-title-vcenter"}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage
                        id={"s.online.catastale.title"}
                        defaultMessage={"Visura Catastale"}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage
                    id={"s.online.catastale.content"}
                    defaultMessage={"<p>Lo Studio Andrea Matera offre il servizio di Visura Catastale online, al costo di €15, attraverso pagamento con PayPal o carta di credito.</p><p>In fase di richiesta/pagamento specificare se si desidera la visura provinciale o nazionale.</p>"}
                    values={{'p': (w) => <p>{w}</p>}}
                />
                <div className={"form-payment"}>
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_s-xclick"/>
                        <input type="hidden" name="hosted_button_id" value="97U5ZQLQN9XML"/>
                        <input type="image" src="https://www.paypalobjects.com/it_IT/IT/i/btn/btn_buynowCC_LG.gif" name="submit" alt="PayPal è il metodo rapido e sicuro per pagare e farsi pagare online."/>
                        <img alt="" border="0" src="https://www.paypalobjects.com/it_IT/i/scr/pixel.gif" width="1" height="1"/>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
};

const Service2 = (props) => {
    return (
        <Modal
            {...props}
            size={"lg"}
            aria-labelledby={"contained-modal-title-vcenter"}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage
                        id={"s.online.camerale.title"}
                        defaultMessage={"Visura Camerale"}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage
                    id={"s.online.camerale.content"}
                    defaultMessage={"<p>Lo Studio Andrea Matera offre il servizio di Visura Camerale online, al costo di €15, attraverso pagamento con PayPal o carta di credito.</p><p>In fase di richiesta/pagamento specificare se si desidera la visura ordinaria o storica.</p>"}
                    values={{'p': (w) => <p>{w}</p>}}
                />
                <div className={"form-payment"}>
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_s-xclick"/>
                        <input type="hidden" name="hosted_button_id" value="MSUVQM3EDCHRY"/>
                        <input type="image" src="https://www.paypalobjects.com/it_IT/IT/i/btn/btn_buynowCC_LG.gif" name="submit" alt="PayPal è il metodo rapido e sicuro per pagare e farsi pagare online."/>
                        <img alt="" border="0" src="https://www.paypalobjects.com/it_IT/i/scr/pixel.gif" width="1" height="1"/>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
};


export default ServiziOnline;