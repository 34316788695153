import React from "react";
import { CalendarCheck } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const FAB = () => {

    const navigate = useNavigate();

    return (
        <div class="fab-container" onClick={() => navigate('/prenota')}>
            <p class="fab-label"><FormattedMessage
                id={"contatti.prenota"}
                defaultMessage={"Prenota una consulenza"}
            /></p>
            <CalendarCheck size={24} weight={'bold'} />
        </div>
    )
}

export default FAB